<template>
  <div :class="['page-content shop-page-content', {'page-content--trialFix' : itsTrial}]">
    <shopTabs :currentType="currentType" @clickTab="clickTab"></shopTabs>
    <div :class="['shop-list-wrap']">
      <div ref="shopList" class="shop-list">
        <shopItem v-for="item in currentList" :item="item" :currentType="currentType" :disable="currentType === 'lives' && item.price > userInfo.coins" @buyItem="buyItem"></shopItem>
      </div>
    </div>
    <shopModal :currentType="currentType" @confirmClose="confirmClose" @buyItemConfirm="buyItemConfirm" :showBuyItemPopUp="showBuyItemPopUp" :currentItem="currentItem" :phoneNumber="phoneNumber"></shopModal>
    <shopSuccessModal :currentType="currentType" @confirmClose="confirmClose" :showSuccessPopUp="showSuccessPopUp" :currentItem="currentItem"></shopSuccessModal>
  </div>
</template>

<script>
import Modal from '@/components/modals/Modal.vue'
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/store/user';
import { useRoute } from 'vue-router'
import shopItem from '../components/shop/shopItem.vue';
import shopModal from '../components/shop/shopModal.vue';
import shopSuccessModal from '../components/shop/shopSuccessModal.vue';
import shopTabs from '../components/shop/shopTabs.vue';

export default {
  name: 'shop',
  components: {
    Modal,
    shopItem,
    shopTabs,
    shopModal,
    shopSuccessModal
  },
  data() {
    return {
      buySomething: true,
      storyList: [],
      currentType: '',
      showBuyItemPopUp: false,
      currentItem: {},
      buyInProcess: false,
      showSuccessPopUp: false,
    };
  },
  async created () {
    this.storeList = await this.getShop();
    this.currentType = this.shopBaseType
  },
  computed: {
    ...mapState(useUserStore, ['userInfo', 'shop', 'shopBaseType','itsTrial']),

    livesList () {
      return this.shop.data.lives;
    },
    coinsList () {
      return this.shop.data.coins;
    },
    currentList() {
      if (this.currentType) {
        console.log(this[`${this.currentType}List`])
        return this[`${this.currentType}List`];
      } else {
        return [];
      }
    },
    thisIsChInIos() {
      const isCh = window.navigator.userAgent.indexOf('CriOS') === -1 ? false : true ;
      return isCh;
    },
    thisIsYA() {
      const YaBrowser = window.navigator.userAgent.indexOf('YaBrowser') === -1 ? false : true;
      const YaSearchBrowser = window.navigator.userAgent.indexOf('YaSearchBrowser') === -1 ? false : true;
      return YaBrowser || YaSearchBrowser;
    },
    phoneNumber () {
      if (this.userInfo.msisdn) {
        const msisdn = this.userInfo.msisdn + '';
        return `+${msisdn.substring(0,1)}&nbsp(${msisdn.substring(1,4)})&nbsp${msisdn.substring(4,7)}-${msisdn.substring(7,9)}-${msisdn.substring(9,11)}`;
      }
      return '';
    },
  },
  methods: {
      ...mapActions(useUserStore, ['getShop', 'storyItem', 'checkProgress', 'changeShopType', 'openSuspendModal']),
    modalClose() {
      this.announceModal = false
    },
    clickTab(type) {
      this.changeShopType(type);
      this.currentType = this.shopBaseType;
      this.$refs.shopList.scrollTo({
          top: 0
        });
    },
    buyItem (item) {
      if (this.userInfo.is_suspended === 1){
        this.openSuspendModal();
      }
      else{
        this.showBuyItemPopUp = true;
        this.currentItem = item;
      }
    },
    async buyItemConfirm () {
      if (this.buyInProcess) {
        return
      }

      this.buyInProcess = true;

      let res = await this.storyItem({
        "price": this.currentItem.price,
        "count": this.currentItem.count,
        "type": this.currentType === 'coins' ? 'coins_for_money' : 'live_for_coins'
      })
      this.showBuyItemPopUp = false;
      this.buyInProcess = false;
      if (res.message) {
        // нет поп-апа на ошибку
      } else {
          if (res != 'undefined' && !res.error){
            if (res.data.isSuccess){
                this.showSuccessPopUp = true;
            }
            if(res.data.inProgress){
              this.showSuccessPopUp = true;
              this.checkProgress({'id': res.data.id});
            }
          }
      }
      this.buyInProcess = false;
    },
    confirmClose () {
      this.showBuyItemPopUp = false;
      this.showSuccessPopUp = false;
      this.currentItem = {};
    },
  },
  watch: {
    shopBaseType (newObj, oldCount) {
      this.currentType = this.shopBaseType
    }
  }
};
</script>
