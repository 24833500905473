<template>
  <Modal
    v-model="showModal"
    @close="confirmClose"
    @confirm="actionConfirm"
    btnText="Оформить подписку"
    :class="['modal-wrapper promo-modal shop-modal error-modal default-modal']"
    >
      <div class="popup-reward popup-shop">
        <div class="popup-shop-icon">
          <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M59.8865 58.7898C57.7999 59.002 55.7833 59.65 53.9714 60.6906C52.1594 61.7311 50.5937 63.1403 49.3791 64.824C48.0733 66.4315 47.1192 68.2874 46.5767 70.2752C46.0343 72.263 45.9151 74.3398 46.2268 76.3751C46.4214 78.4154 47.0527 80.392 48.0789 82.1749C49.1052 83.9578 50.5031 85.5066 52.181 86.7193H52.3561C53.9492 88.0136 55.8035 88.9596 57.7962 89.4946C59.7889 90.0296 61.8745 90.1414 63.9144 89.8226C66.0174 89.582 68.0487 88.9231 69.8851 87.8858C71.7215 86.8485 73.3246 85.4544 74.5969 83.7885C76.2002 81.6642 77.225 79.1716 77.5739 76.5475L90 9.65478L35.1306 0L25.5624 54.4797C23.9296 53.1942 22.0444 52.2549 20.0252 51.7209C18.0061 51.1869 15.8964 51.0696 13.829 51.3765C11.7517 51.6219 9.74766 52.284 7.94043 53.3218C6.13319 54.3597 4.56113 55.7512 3.32164 57.4106C2.01582 59.0181 1.06175 60.874 0.519288 62.8618C-0.0231742 64.8496 -0.142184 66.9264 0.169554 68.9617C0.420817 71.0063 1.09418 72.9786 2.14824 74.7575C3.2023 76.5364 4.61478 78.0843 6.29886 79.306C7.93062 80.5934 9.81568 81.5338 11.8352 82.0679C13.8546 82.602 15.9646 82.7182 18.032 82.4092C20.1066 82.1541 22.1071 81.4878 23.9129 80.4508C25.7188 79.4137 27.2926 78.0273 28.5394 76.3751C30.2371 74.3114 31.4349 71.8941 32.0419 69.3066L40.8403 19.8805L78.0993 26.3779L71.7248 61.8931C70.0793 60.5937 68.1765 59.6467 66.1381 59.1124C64.0997 58.578 61.9705 58.4681 59.8865 58.7898Z" fill="url(#paint0_radial_3703_45568)"/>
            <path d="M59.8865 58.7898C57.7999 59.002 55.7833 59.65 53.9714 60.6906C52.1594 61.7311 50.5937 63.1403 49.3791 64.824C48.0733 66.4315 47.1192 68.2874 46.5767 70.2752C46.0343 72.263 45.9151 74.3398 46.2268 76.3751C46.4214 78.4154 47.0527 80.392 48.0789 82.1749C49.1052 83.9578 50.5031 85.5066 52.181 86.7193H52.3561C53.9492 88.0136 55.8035 88.9596 57.7962 89.4946C59.7889 90.0296 61.8745 90.1414 63.9144 89.8226C66.0174 89.582 68.0487 88.9231 69.8851 87.8858C71.7215 86.8485 73.3246 85.4544 74.5969 83.7885C76.2002 81.6642 77.225 79.1716 77.5739 76.5475L90 9.65478L35.1306 0L25.5624 54.4797C23.9296 53.1942 22.0444 52.2549 20.0252 51.7209C18.0061 51.1869 15.8964 51.0696 13.829 51.3765C11.7517 51.6219 9.74766 52.284 7.94043 53.3218C6.13319 54.3597 4.56113 55.7512 3.32164 57.4106C2.01582 59.0181 1.06175 60.874 0.519288 62.8618C-0.0231742 64.8496 -0.142184 66.9264 0.169554 68.9617C0.420817 71.0063 1.09418 72.9786 2.14824 74.7575C3.2023 76.5364 4.61478 78.0843 6.29886 79.306C7.93062 80.5934 9.81568 81.5338 11.8352 82.0679C13.8546 82.602 15.9646 82.7182 18.032 82.4092C20.1066 82.1541 22.1071 81.4878 23.9129 80.4508C25.7188 79.4137 27.2926 78.0273 28.5394 76.3751C30.2371 74.3114 31.4349 71.8941 32.0419 69.3066L40.8403 19.8805L78.0993 26.3779L71.7248 61.8931C70.0793 60.5937 68.1765 59.6467 66.1381 59.1124C64.0997 58.578 61.9705 58.4681 59.8865 58.7898Z" fill="url(#paint1_radial_3703_45568)"/>
            <defs>
              <radialGradient id="paint0_radial_3703_45568" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15.8823 60.7836) rotate(37.1919) scale(13.2915 13.8936)">
                <stop stop-color="#FFFCA6"/>
                <stop offset="1" stop-color="#FFEF3B"/>
              </radialGradient>
              <radialGradient id="paint1_radial_3703_45568" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(61.2605 68.0877) rotate(49.4574) scale(22.1068 23.4527)">
                <stop stop-color="#FFFCA6"/>
                <stop offset="1" stop-color="#FFF36B" stop-opacity="0"/>
              </radialGradient>
            </defs>
          </svg>

        </div>
        <div>
          <p class="popup-shop-description">Оформите подписку, чтобы получить доступ ко всем функциям сервиса</p>
        </div>
      </div>
      <template #customFooter>
            <p class="popup-shop-footer">Управлять услугой можно в приложении билайн (12+): <a href="http://www.beeline.ru/login">http://www.beeline.ru/login</a></p>
      </template>
  </Modal>
</template>


<script>
  import { useModal } from 'vue-final-modal'
  import Modal from '@/components/modals/Modal.vue'
  import { useUserStore } from '@/store/user';
  import { mapState, mapActions } from 'pinia';

export default {
  name: 'subscribeModal',
  components: {
    Modal,
  },
  props: {
    trialModal: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState(useUserStore, ['itsTrial']),
     showModal () {
      return this.trialModal
    },
  },
  methods: {
    confirmClose () {
      this.$emit('confirmClose');
    },
    actionConfirm () {
      // if (this.itsTrial) {
        window.location.href = '/backend/subscribe_web';
        this.$emit('confirmClose');
      // }
    }
  },
};
</script>
