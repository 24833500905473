<template>


  <div class="super-game-widget" v-if="userInfo.supergame">
    <div class="super-game-widget-bg"></div>
      <div class="super-game-widget-content" :class="`status-${userInfo.supergame.availability}`">
        <div class="super-game-widget-progress">
          <div class="super-game-widget-progress-title">СУПЕРИГРА</div>
          <div class="super-game-widget-progress-line" :class="{'completed': progress() === 100}">
            <div class="super-game-widget-progress-value" :style="{width: `${progress()}%`}"></div>
          </div>
        </div>
        <div class="super-game-widget-cup" :class="{ 'no-wins': userInfo.supergame.finished_count == 0}">
          <span class="finished-value" v-if="userInfo.supergame.finished_count > 0">{{userInfo.supergame.finished_count}}</span>
        </div>
        <div class="super-game-widget-info" @click="superGameStart()">
          <div v-if="userInfo.supergame.availability === 3 || userInfo.supergame.availability === 5">
            Собрано нот: <span>{{userInfo.supergame.quests_progress.answers_count}}/{{userInfo.supergame.quests_progress.questions_count}}</span>
          </div>
          <div class="super-game-widget-timer" v-if="userInfo.supergame.availability === 2">
            Доступна игра <span><vue-countdown :time="expTime()" v-slot="{ days, hours, minutes, seconds }" :transform="transformSlotProps"  @end="onCountDownEnd()">
              {{ hours }}:{{ minutes }}:{{ seconds }}
            </vue-countdown></span>
          </div>
          <div class="super-game-widget-timer" v-if="userInfo.supergame.availability === 6 && userInfo.supergame.upcoming_game <= 86400">
            начнётся через <span><vue-countdown :time="expTime()" v-slot="{ days, hours, minutes, seconds }" :transform="transformSlotProps"  @end="onCountDownEnd()">
              {{ hours }}:{{ minutes }}:{{ seconds }}
            </vue-countdown></span>
          </div>
          <!-- <div class="super-game-widget-timer" v-if="userInfo.supergame.availability === 6 && userInfo.supergame.upcoming_game > 86400">
            Недоступно до: <span>{{dateUpdateSuperGame}}</span>
          </div> -->
          <div class="super-game-widget-timer" v-if="userInfo.supergame.availability === 6 && userInfo.supergame.upcoming_game > 86400">
            Недоступно до:<span>
              {{dateUpdateSuperGame}}
           </span>
          </div>
          <div class="super-game-widget-timer" v-if="userInfo.supergame.availability === 1">
            Можно играть!
          </div>
        </div>
      </div>

      <supergame-trial-modal @confirmClose="confirmClose" :trialModal="trialModal" ></supergame-trial-modal>
  </div>

</template>


<script>
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/store/user';
import VueCountdown from '@chenfengyuan/vue-countdown';
import SupergameTrialModal from '../supergame/supergameTrialModal.vue';

export default {
  name: 'superGameWidget',
  props: {
    puzzle: {
      type: Object,
      default: {}
    },
    info: {
      type: Object,
      default: {}
    },

  },
  components: {
    VueCountdown,
    SupergameTrialModal
  },
  data() {
    return {
      showModal: false,
      hint: false,
      progressValue: 0,
      expTimeVal: false,
      trialModal: false,
    };
  },
  computed: {
    ...mapState(useUserStore, ['userInfo', 'itsTrial', 'itsTrialEnded']),
    dateUpdateSuperGame () {
      let dateUpdateFormat = '00.00.00'
      let dateUpdate;
      let minutes;
      let hours;

      const currentDate = new Date();

      // dateUpdate = currentDate.setDate(currentDate.getDate() + (this.userInfo.supergame.upcoming_game/(86400*1000)));
      // dateUpdate = currentDate.setDate(currentDate.getDate() + (this.userInfo.supergame.upcoming_game/(86400*1000)));
      dateUpdate = currentDate.getTime() + (this.userInfo.supergame.upcoming_game * 1000);
      dateUpdate = new Date(dateUpdate);
      console.log(dateUpdate);
      minutes = dateUpdate.getMinutes() > 9 ? dateUpdate.getMinutes() : `0${dateUpdate.getMinutes()}`;
      hours = dateUpdate.getHours() > 9 ? dateUpdate.getHours() : `0${dateUpdate.getHours()}`;
      dateUpdateFormat = `${dateUpdate.getDate()}.${dateUpdate.getMonth()+1}.${String(dateUpdate.getFullYear()).substr(2, 4)} ${hours}:${minutes}`;
      return dateUpdateFormat;
    }
  },
  created() {

  },
  methods: {
    ...mapActions(useUserStore, ['openSuspendModal']),
    progress () {
      // let pieces = 0;
      if (this.userInfo.supergame.quests_progress)
        this.progressValue = parseInt( ( ( this.userInfo.supergame.quests_progress.answers_count * 100 ) / this.userInfo.supergame.quests_progress.questions_count ));
      return this.progressValue;
    },
    expTime(){
      if (this.userInfo.supergame.availability===2 && this.userInfo.supergame.expires_at > 0){
        this.expTimeVal = true;
        return this.userInfo.supergame.expires_at * 1000
      }
      else if (this.userInfo.supergame.upcoming_game && this.userInfo.supergame.upcoming_game > 0){
        this.expTimeVal = true;
        return this.userInfo.supergame.upcoming_game * 1000
      }

    },
    transformSlotProps(props) {
      const formattedProps = {};

      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);
      });

      return formattedProps;
    },
    superGameStart(){
      if (this.itsTrial || this.itsTrialEnded) {
        this.trialModal = true;
        return;
      }
      if (this.userInfo.is_suspended === 1){
        this.openSuspendModal();
        return;
      }
      if (this.userInfo.supergame.availability === 2 || this.userInfo.supergame.availability === 3 || this.userInfo.supergame.availability === 1)
        this.$router.push('/supergame');
    },
    confirmClose() {
      this.trialModal = false;
    }
  }
};
</script>
