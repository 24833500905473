<template>

  <div class="main-grid">
    <div class="main-grid-item" v-for="item in categoriesList" :key="item.id" @click="game(item)" :class="{'item-disabled': item.questions_count == 0, 'has-daily': item.is_daily_quest, 'completed' : item.category_completed}">
    <img :src="getImageUrl(item.icon_url)" class="main-grid-item-img" alt="" />
      <div class="main-grid-item-caption">
        <div class="main-grid-item-title">{{ item.title }}</div>
        <div class="main-grid-item-progress">{{ item.answers_count }}/{{ item.questions_count }}</div>
      </div>
    </div>
  </div>

</template>


<script>
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/store/user';
import Modal from '@/components/modals/Modal.vue'
import VueCountdown from '@chenfengyuan/vue-countdown';
import { ref, onMounted } from 'vue'

export default {
  name: 'Categories',
  props: {
    puzzle: {
      type: Object,
      default: {}
    },
    info: {
      type: Object,
      default: {}
    },

  },
  components: {
    Modal,
    VueCountdown
  },
  data() {
    return {
      showModal: false,
      hint: false,
      progressValue: 0,
      categories: [],
    };
  },
  computed: {
    ...mapState(useUserStore, ['userInfo']),
    categoriesList () {
      if (this.userInfo.categories)
        this.categories = this.userInfo.categories;

      return this.categories;
    },
  },
  created() {

  },
  methods: {
    ...mapActions(useUserStore, ['startGame', 'setCurrentCategory', 'getUserInfo', 'showNoLivePopUp', 'itsQuestToggle', 'openSuspendModal']),

    getImageUrl(url) {
      return new URL(url, import.meta.url).href
    },
    game(item){
      if (!this.userInfo.is_subscribed && !this.userInfo.trial_lifetime) {
        window.location.href = '/backend/subscribe_web';
        return
      }
      if (this.userInfo.is_suspended === 1){
        this.openSuspendModal()
        return;
      }
      if (this.userInfo.lives > 0) {
        this.setCurrentCategory(item);
        this.itsQuestToggle();
        this.$router.push({path: `/game` })
      }
      else {
        this.showNoLivePopUp();
      }
    },
  }
};
</script>
